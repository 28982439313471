import {alpha} from '@material-ui/core/styles';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const inputs = theme => ({
  // MuiFilledInput: {
  //   input: {
  //     paddingTop: 14,
  //     paddingBottom: 13
  //   },
  //   inputMarginDense: {
  //     paddingTop: 9,
  //     paddingBottom: 8
  //   }
  // },
  // MuiFormHelperText: {
  //   contained: {
  //     marginLeft: 0,
  //     marginRight: 0
  //   }
  // }
  MuiOutlinedInput: {
    marginDense: {
      height: 40,

      '& svg': {
        width: 20,
        height: 20
      }
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: theme.palette.colors.inputOutlined,
      transition: 'all 2s',

      '&.Mui-focused': {
        transition: 'all 2s',
        borderColor: theme.palette.colors.inputOutlinedActive
      },

      '& .Mui-error': {
        borderWidth: '1.5px !important'
      }
    },

    root: {
      '& svg': {
        width: 24,
        height: 24
      },

      inputAdornedStart: {},
      inputAdornedEnd: {},

      '&.Mui-disabled': {
        color: alpha(theme.palette.colors.inputDisabled, 0.38)
      },

      '&.Mui-error': {
        borderWidth: '1.5px !important'
      },

      '&.Mui-focused fieldset, &&:hover.Mui-focused fieldset': {
        borderWidth: '1.5px !important',
        borderColor: [theme.palette.colors.inputOutlinedActive, '!important'],
        transition: 'all .5s'
      },

      '&:hover fieldset': {
        borderWidth: '1px !important',
        borderColor: [theme.palette.colors.inputOutlinedHover, '!important'],
        transition: 'all .5s'
      },

      '&:hover.Mui-disabled fieldset, &.Mui-disabled fieldset': {
        borderWidth: '1px !important',
        border: '1px solid rgba(65, 77, 101, 0.50) !important',
        background: 'rgba(0, 0, 0, 0.05)'
      },

      '&:hover.Mui-error fieldset, &.Mui-focused.Mui-error fieldset, &:hover.Mui-focused.Mui-error fieldset, &.Mui-error fieldset':
        {
          borderWidth: '1.5px !important',
          borderColor: [theme.palette.error.light, '!important'],
          transition: 'all .5s'
        },

      height: 48
    },

    input: {
      paddingTop: 9,
      paddingBottom: 9,
      ...extendedTypography.body1RegularDesktop
    },

    inputMarginDense: {
      paddingTop: 9,
      paddingBottom: 9,
      ...extendedTypography.body1RegularMobile,
      lineHeight: 1.2
    }
  },
  MuiInputLabel: {
    shrink: {
      '& .Mui-error': {
        color: theme.palette.error.light
      }
    },
    outlined: {
      transform: 'translate(14px, 16px) scale(1)',

      '&.shrink.marginDense': {
        transform: 'translate(14px, -8px) scale(0.75)'
      },

      '&.marginDense': {
        transform: 'translate(14px, 10px) scale(1)'
      },

      // '&.focused': {
      //   transform: 'translate(14px, -8px) scale(0.75)'
      // },

      '&.Mui-focused': {
        color: [theme.palette.colors.inputOutlinedActive, '!important']
      },

      '&.Mui-focused.Mui-error': {
        color: [theme.palette.error.light, '!important']
      },

      '&.Mui-disabled': {
        color: alpha(theme.palette.colors.inputDisabled, 0.38)
      }
    },
    marginDense: {
      ...extendedTypography.body1RegularMobile
      // lineHeight: 1.2,
      // transform: 'translate(14px, 20px) scale(1)'
    }
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      height: 14,

      [theme.breakpoints.down('xs')]: {
        fontSize: '0.63em'
      }
    }
  },
  MuiFormHelperText: {
    root: {
      ...extendedTypography.caption2RegularDesktop,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0)
    },
    marginDense: {
      ...extendedTypography.caption2RegularMobile,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0)
    },
    contained: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0)
    }
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.text.primary,

      [theme.breakpoints.down('sm')]: {
        // ...extendedTypography.body1RegularMobile,
        // fontSize: '2em'
      },

      '&.Mui-error': {
        color: theme.palette.common.white
      },
      '&.Mui-error[data-shrink="true"]': {
        color: theme.palette.error.light
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0
    }
  },
  MuiCheckbox: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0
    }
  }
});
