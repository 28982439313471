import React, { useEffect, useRef } from 'react';

const isProd = process.env.ENV === 'production';

const ZopimScript = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current && !window.$zopim && isProd) {
      // eslint-disable-next-line no-undef
      try {
        let delayTime = 300; // milliseconds
        const pathname = document.location.pathname;
        if (
          pathname.indexOf('checkout') !== -1 ||
          pathname.indexOf('success') !== -1 ||
          pathname.indexOf('sell-runescape-gold') !== -1
        ) {
          delayTime = 0; // do not delay zopim on checkout and success pages
        }
        setTimeout(function() {
          window.$zopim ||
            (function(d, s) {
              let z = (window.$zopim = function(c) {
                  z._.push(c);
                }),
                $ = (z.s = d.createElement(s)),
                e = d.getElementsByTagName(s)[0];
              z.set = function(o) {
                z.set._.push(o);
              };
              z._ = [];
              z.set._ = [];
              $.async = !0;
              $.setAttribute('charset', 'utf-8');
              $.src = 'https://v2.zopim.com/?2APhxuafFbp9thEWGbXgkEUNpU50ME6O';
              z.t = +new Date();
              $.type = 'text/javascript';
              e.parentNode.insertBefore($, e);
            })(document, 'script');
        }, delayTime);
      } catch (e) {
        console.log('zopim not loaded...');
      }
    }
    isMounted.current = false;
  });

  return null;
};
export default ZopimScript;
