import * as React from 'react';
import { Typography, Card, CardMedia, CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    // width: 169,
    height: 90,
    // padding: 5,
    textAlign: 'center',
    borderRadius: 10
  },
  hover: {
    '&:hover': {
      boxShadow: '0 0 10px 5px #F2C94C'
    }
  },
  cardActionArea: {
    height: '100%',
    width: 'initial',
    maxWidth: 130,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
    // maxHeight: 60,
  },
  cardHover: {
    display: 'none' // disable button hover effect
  },
  activeCard: {
    // border: "5px solid #F2C94C"
    boxShadow: '0 0 0 5px #F2C94C'
  },
  image: {
    width: 'auto',
    margin: '0 auto'
    // minHeight: 50
  },
  description: {
    '& p': {
      margin: 0
    }
  },
  disabled: {
    opacity: '0.4!important',
    '&:active': {
      boxShadow: '0 0 0 5px #F2C94C'
    }
  }
}));

const PaymentMethod = ({ method, selected, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Card
      onClick={onClick}
      className={clsx(
        classes.card,
        selected && classes.activeCard,
        disabled ? classes.disabled : classes.hover
      )}
    >
      <CardActionArea
        className={classes.cardActionArea}
        classes={{
          focusHighlight: classes.cardHover
        }}
      >
        <CardMedia
          className={classes.image}
          component="img"
          image={method.image.url}
          title={method.name}
          align="center"
        />
        {method.description && (
          <Typography
            variant="body1"
            component="div"
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(method.description)
            }}
          />
        )}
      </CardActionArea>
    </Card>
  );
};

PaymentMethod.propTypes = {
  method: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

export default PaymentMethod;
