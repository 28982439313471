export const dialog = theme => ({
  MuiDialog: {
    paper: {
      backgroundColor: theme.palette.backgrounds.box,
      color: theme.palette.common.white
    },
    paperWidthXs: {maxWidth: 320},
    paperWidthLg: {maxWidth: 530}
  }
});
