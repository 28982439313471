import {gql} from '@apollo/client';

export const TestimonialQuery = gql`
  query Testimonial($filter: TestimonialsFilterInput) {
    testimonialList(filter: $filter) {
      edges {
        node {
          id
          stars
          productType
          user
          reviewText
          created
          pageId {
            id
            slug
          }
          image {
            url
            alt
          }
          link
        }
      }
    }
  }
`;

export const ReviewioDetailsQuery = gql`
  query ReviewioDetails {
    reviewioDetails {
      title
      totalReviews
      averageRating
      reviews {
        title
        username
        comment
        rating
        timeago
      }
    }
  }
`;

export const MenuItemFragment = gql`
  fragment MenuItem on MenuItem {
    id
    name
    label
    level
    url
    loyaltyLevel {
      id
      pointsPerUsd
    }
    page {
      id
      slug
    }
  }
`;

export const MenuFragment = gql`
  ${MenuItemFragment}
  fragment Menu on Menu {
    id
    name
    items {
      ...MenuItem
      children {
        ...MenuItem
      }
    }
  }
`;

export const ShopQuery = gql`
  ${MenuFragment}
  query Shop {
    shop {
      domain {
        host
        url
      }
      name
      footerText
      description
      requestCurrency
      customerSetPasswordUrl
      customerAccountActivationUrl
      navigation {
        main {
          ...Menu
        }
        secondary {
          ...Menu
        }
        footer {
          ...Menu
        }
      }
    }
  }
`;

export const ShopCurrencyQuery = gql`
  query Shop {
    shop {
      requestCurrency
    }
  }
`;

export const UserCountryCodeQuery = gql`
  query UserCountryCode {
    paymentCountries {
      userCountryCode
    }
  }
`;

export const CategoryLabelsQuery = gql`
  query categoryLabels($filter: CategoryLabelFilterInput) {
    categoryLabels(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
