import {alpha, darken} from '@material-ui/core/styles';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const button = theme => ({
  MuiButton: {
    root: {textTransform: 'capitalize'},
    text: {'&:hover': {backgroundColor: 'transparent'}},
    textPrimary: {color: theme.palette.secondary.main},
    textSizeSmall: {
      ...extendedTypography.caption1Desktop,
      fontWeight: 500,

      [theme.breakpoints.down('sm')]: {
        ...extendedTypography.body2MediumMobile
      }
    },

    contained: {
      boxShadow: 'none',
      padding: theme.spacing(1.5, 3),
      ...extendedTypography.body1SemiboldDesktop,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.25, 2),
        ...extendedTypography.body1SemiboldMobile
      },

      '&:hover': {boxShadow: 'none'}
    },
    containedSizeLarge: {
      padding: theme.spacing(1.875, 4),
      ...extendedTypography.subtitleSemiboldDesktop,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 3),
        ...extendedTypography.subtitleSemiboldMobile
      }
    },
    containedSizeSmall: {
      padding: theme.spacing(1.25, 2),
      ...extendedTypography.body2SemiboldDesktop,

      // [theme.breakpoints.down('sm')]: {
      //   padding: theme.spacing(0.875, 1.5),
      //   ...extendedTypography.body2SemiboldMobile
      // }

      '&.mobile': {
        padding: theme.spacing(0.875, 1.5),
        ...extendedTypography.body2SemiboldMobile
      }
    },
    containedSecondary: {
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${darken(
          theme.palette.secondary.main,
          0.1
        )} !important` // action hover dark mode
      },

      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.26)'
      }
    },
    containedPrimary: {
      backgroundColor: theme.palette.colors.primaryButton,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${darken(
          theme.palette.colors.primaryButton,
          0.5
        )} !important`
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.26)'
      }
    },
    outlined: {
      padding: theme.spacing(0.875, 2),
      ...extendedTypography.body1SemiboldDesktop,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.125, 2),
        ...extendedTypography.body1SemiboldMobile
      },

      '&.mobile': {
        padding: theme.spacing(1, 1.5),
        ...extendedTypography.body2SemiboldDesktop
      }
    },
    outlinedPrimary: {
      color: theme.palette.colors.primaryButton,
      borderColor: theme.palette.colors.primaryButton,
      '&:hover': {
        borderColor: theme.palette.colors.primaryButton,
        backgroundColor: `${alpha(
          theme.palette.colors.primaryButton,
          0.08
        )} !important`
      },

      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.26)'
      }
    },
    outlinedSizeSmall: {
      padding: theme.spacing(1.1875, 2),
      ...extendedTypography.body2SemiboldDesktop,

      '&.mobile': {
        padding: theme.spacing(0.875, 1.5),
        ...extendedTypography.body2SemiboldMobile
      }

      // [theme.breakpoints.down('sm')]: {
      //   padding: theme.spacing(0.875, 1.5),
      //   ...extendedTypography.body2SemiboldMobile
      // }
    }
  },
  MuiIconButton: {
    sizeSmall: {
      padding: theme.spacing(1)
    }
  }
});
