import palette from './palette';
import {typography} from './typography';

const themeOptions = settings => {
  const {mode} = settings;

  return {
    palette: palette(mode),
    ...typography(),
    zIndex: {
      popper: 1300,
      appBar: 1100
    }
  };
};

export default themeOptions;
