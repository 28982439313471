import * as React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  relative: {
    position: ' relative'
  },
  moveToCenter: {
    top: '10rem'
  }
});

const Loader = ({ moveToCenter, relativePosition, ...props }) => {
  const classes = useStyles();
  const defaultSize = props.size || 200;
  return (
    <div className="loader" align="center" style={{ margin: '0 auto' }}>
      <CircularProgress
        size={defaultSize}
        className={clsx(
          relativePosition && classes.relative,
          moveToCenter && classes.moveToCenter
        )}
        {...props}
      />
    </div>
  );
};

Loader.propTypes = {
  moveToCenter: PropTypes.bool,
  relativePosition: PropTypes.bool
};

Loader.defaultProps = {
  moveToCenter: true,
  relativePosition: true
};

export default Loader;
