export const snackbar = (theme) => ({
  MuiSnackbarContent: {
    root: {
      minHeight: 48,
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'auto',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
      padding: theme.spacing(1.5),
      color: theme.palette.backgrounds.dark
    },
    message: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      gap: theme.spacing(1),
      padding: 0
    },
    MuiSnackbarContent: {
      action: {
        alignItems: 'flex-start'
      }
    }
  }
});
