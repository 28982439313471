import { useContext } from 'react';

import StorefrontContext from 'src/context/StorefrontContext';

export default function useStorefrontData() {
  const {
    user,
    token,
    currency,
    shop,
    page,
    updateCurrency,
    handleLogin,
    handleLogout,
    updateUser
  } = useContext(StorefrontContext);
  return {
    user,
    token,
    currency,
    shop,
    page,
    updateCurrency,
    handleLogin,
    handleLogout,
    updateUser
  };
}
