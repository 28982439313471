import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

export const styles = {
  root: {
    width: '100%',
    border: '1px solid #ddd'
  },
  select: {
    padding: '8px 10px 8px',
    backgroundColor: '#fff',
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
};
