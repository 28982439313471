export const extendedTypography = {
  /* Desktop/Body 1 - Medium */
  body1MediumDesktop: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 26 / 16
  },
  /* Desktop/Body 1 - Regular */
  body1RegularDesktop: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 24 / 16
  },
  /* Desktop/Body 1 - Semibold */
  body1SemiboldDesktop: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 24 / 16
  },
  /* Desktop/Body 2 - Medium */
  body2Medium: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 20 / 14
  },
  /* Desktop/Body 2 - Regular */
  body2RegularDesktop: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 20 / 12
  },
  /* Desktop/Body 2 - Semibold */
  body2SemiboldDesktop: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 20 / 14
  },
  h6Desktop: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 25.6 / 18
  },
  /* Desktop/H5 */
  h5Desktop: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 32 / 20
  },
  /* Desktop/H5 - Semibold */
  h5SemiboldDesktop: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 30 / 20
  },
  h5MediumDesktop: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 30 / 20
  },
  /* Desktop/H4 */
  h4Desktop: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 38 / 24
  },
  /* Desktop/H4 - Semibold */
  h4SemiboldDesktop: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 36 / 24,
    fontFamily: 'Raleway',
    fontVariantNumeric: 'lining-nums proportional-nums'
  },
  /* Desktop/H4 - Regular */
  h4RegularDesktop: {
    fontVariantNumeric: 'lining-nums proportional-nums',

    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 36 / 24,
    letterSpacing: '0.24px'
  },

  /* Desktop/H3 */
  h3Desktop: {
    fontVariantNumeric: 'lining-nums proportional-nums',

    fontFamily: 'Raleway',
    fontWeight: 600,
    fontSize: 28,
    lineHeight: 42 / 28,
    letterSpacing: '0.28px'
  },
  h2Desktop: {
    fontVariantNumeric: 'lining-nums proportional-nums',

    fontFamily: 'Raleway',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 48 / 32,
    letterSpacing: '0.32px'
  },
  /* Desktop/Title */
  titleDesktop: {
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: 64,
    lineHeight: 80 / 64,
    letterSpacing: '0.64px'
  },
  /* Desktop/Subtitle - Semibold */
  subtitleSemiboldDesktop: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 26 / 18
  },
  /* Desktop/Caption 1 */
  caption1Desktop: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 20 / 13
  },
  /* Desktop/Caption 2 - Regular */
  caption2RegularDesktop: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 18 / 12
  },
  /* Desktop/Caption 2 - Medium */
  caption2MediumDesktop: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 18 / 12
  },
  /* Desktop/Subtitle - Medium */
  subtitleMediumDesktop: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 26 / 18
  },

  /*-----------------MOBILE------------*/

  /* Mobile/H5 - Mobile */
  h5Mobile: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 26 / 16
  },
  /* Mobile/H5 - Medium - Mobile */
  h5MediumMobile: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 26 / 18,
    letterSpacing: '0.18px'
  },
  /* Mobile/H5 - Semibold - Mobile */
  h5SemiboldMobile: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 28 / 18
  },
  /* Mobile/H4 - Mobile */
  h4Mobile: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 28 / 18
  },
  /* Mobile/H4 - Semibold */
  h4SemiboldMobile: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 30 / 20,
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFamily: 'Raleway'
  },
  /* Mobile/H4 - Regular - Mobile */
  h4RegularMobile: {
    fontVariantNumeric: 'lining-nums proportional-nums',

    fontFamily: 'Raleway',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 30 / 20,
    letterSpacing: '0.2px'
  },
  /* Mobile/H3 - Mobile */
  h3Mobile: {
    fontVariantNumeric: 'lining-nums proportional-nums',

    fontFamily: 'Raleway',
    fontWeight: 600,
    fontSize: 22,
    lineHeight: 32 / 22,
    letterSpacing: '0.22px'
  },
  h2Mobile: {
    fontFamily: 'Raleway',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: 38 / 26,
    letterSpacing: '0.26px'
  },
  h1Mobile: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: 48 / 32
  },

  /* Mobile/Body 1 - Semibold - Mobile */
  body1SemiboldMobile: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 20 / 14
  },
  /* Mobile/Body 2 - Semibold - Mobile */
  body2SemiboldMobile: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 18 / 12
  },
  /* Mobile/Body 2 - Medium - Mobile */
  body2MediumMobile: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 19 / 12
  },
  /* Mobile/Body 1 - Medium - Mobile */
  body1MediumMobile: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 22 / 14
  },
  /* Mobile/Body 1 - Medium - Mobile - Extra font style (absent in style guide) */
  body1MediumMobileExtra: {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 22 / 14
  },
  /* Mobile/Body 1 - Regular - Mobile */
  body1RegularMobile: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 22 / 14
  },
  /* Mobile/Body 2 - Regular - Mobile */
  body2RegularMobile: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 18 / 12
  },
  /* Mobile/Caption 2 - Regular - Mobile */
  caption2RegularMobile: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 16 / 10
  },
  /* Mobile/Caption 2 - Medium - Mobile */
  caption2MediumMobile: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 16 / 10
  },
  /* Mobile/Caption 1 - Mobile */
  caption1Mobile: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 16 / 11
  },
  /* Mobile/Caption 1 - Regular - Mobile */
  caption1RegularMobile: {
    fontWeight: 400,
    fontSize: 11,
    lineHeight: 18 / 11
  },
  subtitleMediumMobile: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 24 / 16
  },
  /* Mobile/Subtitle - Semibold - Mobile */
  subtitleSemiboldMobile: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16
  }
};
