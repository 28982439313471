import dynamic from 'next/dynamic';
import AppBar from '@material-ui/core/AppBar';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles} from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';

import {maybe} from 'src/core/utils';
import useStorefrontData from 'src/hooks/useStorefrontData';

const DesktopMenu = dynamic(
  () => import('src/views/HeaderNext/components/DesktopMenu')
);

const MobileMenu = dynamic(
  () => import('src/views/HeaderNext/components/MobileMenu')
);

export const useStyles = makeStyles(theme => ({
  appBar: {
    // boxSizing: 'border-box',
    backgroundColor: 'transparent',
    background:
      'linear-gradient(180deg, #0F1729 0%, rgba(15, 23, 41, 0.00) 100%)',
    transition: 'all 1s'
    // paddingRight: '0 !important'
  },
  appBarFixed: {
    backgroundColor: theme.palette.colors.background,
    zIndex: theme.zIndex.appBar + 1
    // this is temp workaround for jumping currency dropdown
    // behavior when appBar is fixed. In mac it's added automatically,
    // but on windows doesn't
    // paddingRight: '15px'
  },
  // customAppBar: ({bg}) => ({
  //   zIndex: theme.zIndex.appBar + 1,
  //   backgroundColor: '#19253F'
  // })
  customAppBar: {
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: '#19253F'
  },
  customAppBarNew: {
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: '#0c1121'
  }
}));

export function HeaderNext({isOldPage = false, isNewPage = false}) {
  const {shop, user} = useStorefrontData();
  // const topMenu = maybe(() => shop.navigation.main, null);
  const bottomMenu = maybe(() => shop.navigation.secondary, null);
  const classes = useStyles();
  // const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const triggerElevationScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  return (
    <AppBar
      position='sticky'
      elevation={triggerElevationScroll ? 1 : 0}
      classes={{
        root: clsx(
          isOldPage && classes.customAppBar,
          isNewPage && classes.customAppBarNew,
          !isOldPage && !isNewPage && classes.appBar,
          triggerElevationScroll &&
            !isOldPage &&
            !isNewPage &&
            classes.appBarFixed
        )
      }}
    >
      <HiddenCss smDown>
        <DesktopMenu
          menu={bottomMenu}
          customer={user} /*isHidden={isMobile}*/
        />
      </HiddenCss>
      <HiddenCss mdUp>
        <MobileMenu
          // topMenu={topMenu}
          menu={bottomMenu}
          customer={user}
          // isHidden={!isMobile}
        />
      </HiddenCss>
    </AppBar>
  );
}

HeaderNext.propTypes = {
  isOldPage: PropTypes.bool,
  isNewPage: PropTypes.bool
};
