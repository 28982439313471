import React from 'react';
import * as Sentry from '@sentry/react';
import Head from 'next/head';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.onLoad(() => {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.ENV,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          networkDetailAllowUrls:
            typeof window !== 'undefined'
              ? [window.location.origin, 'https://api.probemas.com/graphql/']
              : undefined,
          blockAllMedia: false,
          maskAllText: false,
          maskAllInputs: true
        })
      ],
      maxValueLength: 1000,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      sampleRate: 0.2,
      tracesSampleRate: 0.2,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        "Can't find variable: fbq",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // https://github.com/i18next/react-i18next/issues/1625#issuecomment-1627377187
        't is not a function'
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /.*\/web-vitals\/.*/i // nextjs web-vitals
      ]
    });
  });
}

const SentryLazyLoad = () => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) return null;

  return (
    <Head>
      <script
        src="https://js.sentry-cdn.com/4c3ebd1db5914529b1e3f1d801bb3f8e.min.js"
        crossOrigin="anonymous"
      />
    </Head>
  );
};
export default SentryLazyLoad;
