import {divider} from './divider';
import {inputs} from './inputs';
import {tooltip} from './tooltip';
import {button} from './button';
import {dialog} from './dialog';
import {snackbar} from './snackbar';
import {backdrop} from './backdrop';

export const overrides = theme => {
  const backdropOverrides = backdrop(theme);
  const buttonOverrides = button(theme);
  const dividerOverrides = divider(theme);
  const inputsOverrides = inputs(theme);
  const tooltipOverrides = tooltip(theme);
  const dialogOverrides = dialog(theme);
  const snackbarOverrides = snackbar(theme);

  return Object.assign(
    backdropOverrides,
    buttonOverrides,
    dialogOverrides,
    dividerOverrides,
    inputsOverrides,
    snackbarOverrides,
    tooltipOverrides
  );
};
