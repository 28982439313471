import * as React from 'react';
import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Typography, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import useStorefrontData from 'src/hooks/useStorefrontData';
import {
  getCountryFromStorage,
  maybe,
  saveCountryToStorage,
  savePaymentMethodStorage
} from 'src/core/utils';

import PaymentMethod from 'src/components/PaymentMethod';
import {
  PaymentCountriesQuery,
  GetPaymentMethodsQuery
} from 'src/components/PaymentMethods/queries';
import CountrySelect from 'src/components/CountrySelect';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: '1.1rem',
    lineHeight: '36px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem'
    }
  },
  titleRow: {
    paddingBottom: 15,
    paddingTop: 15
  },
  method: {
    marginBottom: '1.2rem',
    '@media (max-width:350px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  disabledText: {
    marginTop: 5,
    marginBottom: -13,
    fontSize: '0.8rem',
    color: 'darkred'
  },
  highlightText: {
    textDecoration: 'none',
    backgroundPositionY: '106%',
    backgroundImage: 'linear-gradient( transparent 50%, gold 50%)',
    transition: 'background 100ms ease',
    // backgroundSize: '2px',
    backgroundSize: 'auto 175%',
    borderRadius: 30
    // textShadow: '1px 2px 2px rgba(0, 0, 0, 0.75)',
  }
}));

export const ACTIVE_PAYMENT_METHOD = 'activePaymentMethod';
export const ACTIVE_COUNTRY = 'activeCountry';

const PaymentMethods = ({totalPrice, checkoutMethod, onChange, category}) => {
  const classes = useStyles();
  const {user} = useStorefrontData();
  let defaultCountry = getCountryFromStorage();

  const [methods, setMethods] = useState([]);
  const [activeCountryCode, setActiveCountryCode] = useState(defaultCountry);
  const [activeMethod, setActiveMethod] = useState();
  const [highlight, setHighlight] = useState();

  const highlightIfDisabled = (disabled, index) => {
    if (disabled) {
      setHighlight(index);
      setTimeout(() => setHighlight(null), 1000);
    }
  };

  const changeMethod = method => {
    setActiveMethod(method);
    onChange(method);
    savePaymentMethodStorage(method);
  };

  function verifyMethodAvailability(method) {
    const methodAvailability = {
      disabled: false,
      disabledText: false
    };

    if (totalPrice) {
      if (method.minUsdAmount && method.minUsdAmount > totalPrice.amount) {
        methodAvailability.disabled = true;
        methodAvailability.disabledText = `$${method.minUsdAmount} Minimum`;
      }

      if (method.maxUsdAmount && method.maxUsdAmount < totalPrice.amount) {
        methodAvailability.disabled = true;
        methodAvailability.disabledText = `$${method.maxUsdAmount} Maximum`;
      }
    }

    return methodAvailability;
  }

  // load default country from localStorage first and if not - take it from request
  const filterBy = {
    country: activeCountryCode,
    onlyEnabledMethods: true,
    category
  };

  const {loading} = useQuery(GetPaymentMethodsQuery, {
    variables: {
      filter: filterBy
    },
    fetchPolicy: 'cache-and-network',
    skip: activeCountryCode === null || !category,
    onCompleted: data => {
      if (data && data.paymentMethods.edges.length) {
        const _methods = data.paymentMethods.edges;
        setMethods(_methods);

        for (const {node} of _methods) {
          const availability = verifyMethodAvailability(node);

          if (!availability.disabled) {
            // setActiveMethod(node);
            changeMethod(node);
            break;
          }
        }
      }
    }
  });
  const {data: countriesData} = useQuery(PaymentCountriesQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (!defaultCountry) {
        defaultCountry = countriesData.paymentCountries.userCountryCode;
        setActiveCountryCode(defaultCountry);
      }
    }
  });
  const availableCountries = maybe(
    () =>
      countriesData.paymentCountries &&
      countriesData.paymentCountries.availableCountryCodes,
    []
  );

  const changeCountry = countryCode => {
    setActiveCountryCode(countryCode);
    saveCountryToStorage(countryCode);
  };

  useEffect(() => {
    if (
      checkoutMethod &&
      activeMethod &&
      checkoutMethod.name !== activeMethod.name
    ) {
      onChange(activeMethod);
    }
  }, [checkoutMethod]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.titleRow}>
        <Grid item xs={12} sm={6}>
          <Typography variant='h5' component='h5' className={classes.title}>
            Select payment method
          </Typography>
        </Grid>
        {user && user.isStaff && (
          <Grid item xs={12} sm={6}>
            <CountrySelect
              loading={loading}
              availableCountries={availableCountries}
              countryCode={activeCountryCode}
              setCountry={changeCountry}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} className={classes.field}>
        {loading && !methods.length ? (
          [...Array(9)].map((item, index) => (
            <Grid
              item
              lg={4}
              xs={6}
              key={`skeleton-${index}`}
              className={classes.method}
            >
              <Skeleton variant='rect' height={90} />
            </Grid>
          ))
        ) : methods.length ? (
          methods.map(({node: method}, index) => {
            const {disabled, disabledText} = verifyMethodAvailability(method);
            return (
              <Grid
                item
                className={classes.method}
                key={method.id}
                lg={4}
                xs={6}
                onClick={e => {
                  if (!disabled) {
                    changeMethod(method);
                  }
                }}
              >
                <PaymentMethod
                  method={method}
                  disabled={disabled}
                  onClick={() => highlightIfDisabled(disabled, index)}
                  selected={
                    activeMethod ? activeMethod.id === method.id : index === 0
                  }
                />
                {disabled && (
                  <Typography
                    variant='body2'
                    align='center'
                    className={clsx(
                      classes.disabledText,
                      highlight === index && classes.highlightText
                    )}
                  >
                    {disabledText}
                  </Typography>
                )}
              </Grid>
            );
          })
        ) : (
          <Typography>
            No payment methods found. Please contact our support team.
          </Typography>
        )}
      </Grid>
    </div>
  );
};

PaymentMethods.propTypes = {
  totalPrice: PropTypes.object,
  onChange: PropTypes.func
};

PaymentMethods.defaultProps = {
  onChange: ({...props}) => null
};

export default PaymentMethods;
