import * as React from 'react';
import {CssBaseline} from '@material-ui/core';
import {
  ThemeProvider,
  StylesProvider,
  makeStyles,
  createStyles
} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
import * as Sentry from '@sentry/react';
import * as mediaQuery from 'css-mediaquery';

import appTheme from 'src/themes/theme';
import {BackdropLoadingProvider} from 'src/context/BackdropLoadingContext';
import {ModalProvider} from 'src/context/ModalContext';
import {GoogleProvider} from 'src/context/DataLayerContext';
import {StorefrontProvider} from 'src/context/StorefrontContext';

import SentryLazyLoad from 'src/components/SentryLazyLoad';
import ZopimScript from 'src/components/ZopimScript';
import ScrollToTop from 'src/components/ScrollTop';

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      // '@font-face': [Metropolis, MetropolisBold, MetropolisSemiBold],
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      a: {
        color: '#26A5D4',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
          // color: '#1a7393',
          opacity: 0.8
          // textDecoration: 'underline'
        }
      },
      img: {
        maxWidth: '100%',
        height: 'auto'
      },
      '#__next': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      },
      '#content': {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column'
      }
    }
    // new themeNext classes:
    // snackbarRoot: {
    //   maxWidth: 'calc(100% - 24px)',
    //   minHeight: 48,
    //   display: 'grid',
    //   gridAutoFlow: 'column',
    //   gridAutoColumns: 'minmax(auto, 1fr)',
    //   justifyContent: 'space-between',
    //   alignItems: 'flex-start',
    //   gap: theme.spacing(1),
    //   padding: theme.spacing(1.5),
    //   color: theme.palette.backgrounds.dark
    // },
    // snackbarMessage: {
    //   display: 'grid',
    //   gridAutoFlow: 'column',
    //   alignItems: 'center',
    //   alignSelf: 'center',
    //   gap: theme.spacing(1),
    //   padding: 0,
    //   ...extendedTypography.body2SemiboldDesktop
    // },
    // snackbarContentAction: {
    //   alignItems: 'flex-start'
    // },
    // snackbarPadding: { paddingLeft: theme.spacing(1.5) },
    // snackbarSuccess: {
    //   color: theme.palette.backgrounds.dark
    // }
  })
);

export const App = ({page, shop, deviceType, reviews, children}) => {
  useStyles();
  const ssrMatchMedia = query => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width:
        deviceType === 'mobile'
          ? '0px'
          : deviceType === 'tablet'
            ? '960px'
            : '1280px'
    })
  });

  return (
    <Sentry.ErrorBoundary fallback='An error has occurred'>
      <StorefrontProvider shop={shop} page={page} reviews={reviews}>
        <ThemeProvider
          theme={{
            theme: appTheme,
            props: {
              // Change the default options of useMediaQuery for ssr
              MuiUseMediaQuery: {ssrMatchMedia}
            }
          }}
        >
          <SentryLazyLoad />
          <CssBaseline />
          <ZopimScript />
          <GoogleProvider>
            <StylesProvider>
              <SnackbarProvider
                autoHideDuration={2000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                // TODO: use these classes after applying new design to all project.
                // This requires new theme <ThemeProvider theme="themeNext">
                classes={
                  {
                    // root: classes.snackbarRoot,
                    // contentRoot: classes.snackbarContent,
                    // action: classes.snackbarContentAction,
                    // message: classes.snackbarMessage,
                    // lessPadding: classes.snackbarPadding,
                    // variantSuccess: classes.snackbarSuccess
                    // variantError: classes.snackbarError,
                    // variantWarning: classes.snackbarWarning,
                    // variantInfo: classes.snackbarInfo
                  }
                }
                iconVariant={
                  {
                    // success: (
                    //   <CheckInCircleIcon />
                    // )
                    // warning: (
                    //   <WarningIcon
                    //     classes={{
                    //       root: clsx(
                    //         classes.warningIcon,
                    //         classes.icon
                    //       )
                    //     }}
                    //   />
                    // ),
                    // error: (
                    //   <CrossCircleIcon
                    //     classes={{ root: classes.icon }}
                    //   />
                    // ),
                    // info: (
                    //   <InvertedExclamationCircleIcon
                    //     classes={{ root: classes.icon }}
                    //   />
                    // )
                  }
                }
              >
                <ModalProvider>
                  <BackdropLoadingProvider>
                    <ScrollToTop />
                    {children}
                  </BackdropLoadingProvider>
                </ModalProvider>
              </SnackbarProvider>
            </StylesProvider>
          </GoogleProvider>
        </ThemeProvider>
      </StorefrontProvider>
    </Sentry.ErrorBoundary>
  );
};
