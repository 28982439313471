import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function ScrollToTop() {
  const router = useRouter();
  const { asPath } = router;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [asPath]);

  return null;
}
