import {createTheme, responsiveFontSizes} from '@material-ui/core/styles';

import {overrides} from './overrides';
import themeOptions from './themeOptions';

const theme = createTheme(themeOptions({mode: 'light'}));

theme.overrides = overrides(theme);

theme.props = {
  MuiTooltip: {
    interactive: true
  },
  MuiButton: {
    disableRipple: true
  },
  MuiOutlinedInput: {
    // notched: false
  }
  // MuiInputLabelL: {
  //   shrink: false
  //   // disableAnimation: true
  // }
};

export const themeNext = responsiveFontSizes(theme);
