import {createContext, useEffect, useMemo, useRef, useState} from 'react';
// import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import cookie from 'js-cookie';

import {maybe} from 'src/core/utils';

import {CustomerQuery} from '../gql/queries/account';
import {ShopCurrencyQuery} from '../gql/queries/general';
import {AUTH_TOKEN, CURRENCY_KEY, DEFAULT_CURRENCY} from '../config';
import {waitForZopim} from '../core/zopim';
import {
  TokenDestroyMutation,
  TokenVerifyMutation
} from '../views/Header/mutations';
import {
  loadUserFromStorage,
  removeUserFromStorage,
  saveUserToStorage
} from '../core/auth';

const StorefrontContext = createContext({
  shop: null,
  user: null,
  currency: DEFAULT_CURRENCY,
  token: null,
  page: null,
  updateCurrency: () => {},
  updateUser: () => {},
  handleLogin: () => {},
  handleLogout: () => {}
});

// const isProd = process.env.ENV === 'production';

const saveUserToApolloCache = (user, cache) => {
  cache.writeQuery({
    query: CustomerQuery,
    data: {customer: user}
  });
};

export const StorefrontProvider = ({shop, page, children}) => {
  const isMounted = useRef(true);
  const authToken = cookie.get(AUTH_TOKEN);
  const client = useApolloClient();
  const {data: MeData} = useQuery(CustomerQuery);
  // use user from cache if we already loaded it
  const defaultUser = maybe(() => MeData.customer, undefined);
  // const defaultUser = maybe(() => MeData.customer, undefined);
  const [user, setUser] = useState(defaultUser);
  const [currency, setCurrency] = useState(
    cookie.get(CURRENCY_KEY) || DEFAULT_CURRENCY
  );

  const [tokenDestroyFunc] = useMutation(TokenDestroyMutation);

  const updateCurrency = newCurrency => {
    setCurrency(newCurrency);
    cookie.set(CURRENCY_KEY, newCurrency);
  };

  // update currency only if it is EUR, as per Lukas request
  // https://discord.com/channels/@me/1082413570777677877/1093196222510149672
  const hackyCurrencyUpdate = c => {
    if (c === 'EUR') {
      updateCurrency(c);
    }
  };

  useQuery(ShopCurrencyQuery, {
    onCompleted: data => {
      if (!cookie.get(CURRENCY_KEY) && currency !== data.shop.requestCurrency) {
        hackyCurrencyUpdate(data.shop.requestCurrency);
      }
    }
  });

  const handleLogin = (_user, token) => {
    if (_user && _user?.currency !== null && _user?.currency !== currency) {
      hackyCurrencyUpdate(_user.currency);
    }

    setUser(_user);
    saveUserToStorage(_user, token);
    saveUserToApolloCache(_user, client.cache);
  };

  const handleLogout = async () => {
    try {
      // wrap it in try/catch because its possible to get Signature expired error
      // when to token sent
      await tokenDestroyFunc();
    } catch (e) {}

    setUser(null);
    removeUserFromStorage();
    saveUserToApolloCache(null, client.cache);
  };

  const [tokenVerifyFunc] = useMutation(TokenVerifyMutation, {
    onError: () => handleLogout(), // TODO: proper token validation
    onCompleted: ({tokenVerify}) => handleLogin(tokenVerify.user)
  });

  const updateUser = user => {
    setUser(user);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (authToken) {
        tokenVerifyFunc({
          variables: {
            token: authToken
          }
        });

        // workaround to avoid flickering for auth content
        // while we sending request to server to verify token
        // NOTE: might be not secure
        // TODO: check possible secure solutions
        if (!user) {
          const storageUser = loadUserFromStorage();
          handleLogin(storageUser);
        }
        // saveUserToApolloCache(storageUser, client.cache);
      } else {
        // means that we tried to identify user but he is not logged in
        // NOTE: it is allow us to make sure that attempt to get user already done
        setUser(null);
      }
    }

    // if (requestCurrency && !cookie.get(CURRENCY_KEY, null)) {
    //   updateCurrency(requestCurrency);
    // }
    // else if (userCurrency && isUserCurrency.current) {
    //   updateCurrency(userCurrency);
    //   isUserCurrency.current = false;
    // }
    isMounted.current = false;
    waitForZopim(user);
  }, [authToken, client, currency, tokenVerifyFunc, user]);

  const contextValue = useMemo(
    () => ({
      shop,
      currency,
      user,
      page,
      token: authToken,
      updateCurrency,
      updateUser,
      handleLogin,
      handleLogout
    }),
    [authToken, currency, page, shop, user]
  );

  return (
    <StorefrontContext.Provider value={contextValue}>
      <>{children}</>
    </StorefrontContext.Provider>
  );
};

export const StorefrontContextConsumer = StorefrontContext.Consumer;
export default StorefrontContext;
