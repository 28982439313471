import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { createContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const BackdropLoadingContext = createContext();

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff'
  }
}));

export const BackdropLoadingProvider = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <BackdropLoadingContext.Provider
      value={{
        showLoading: () => setOpen(true),
        stopLoading: () => setOpen(false)
      }}
    >
      <>
        {children}
        {open && (
          <Backdrop
            className={classes.backdrop}
            open={open}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    </BackdropLoadingContext.Provider>
  );
};

export const BackdropLoadingConsumer = BackdropLoadingContext.Consumer;
export default BackdropLoadingContext;
