import React from 'react';
import Image from 'next/image';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { styles } from './styled';
import countriesList from './_countries';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  const code = isoCode.toLowerCase();
  return (
    <Image
      // src={require(`./flags/${code}.gif`)}
      src={`/flags/${code}.gif`}
      width={15}
      height={15}
      title={isoCode}
      alt={isoCode}
    />
  );
}

class CountrySelect extends React.Component {
  transformCountries(availableCountries) {
    const countriesToUse = [];
    countriesList.map(item => {
      if (availableCountries.includes(item.code)) {
        countriesToUse.push(item);
      }
    });
    return countriesToUse;
  }

  handleChange = (e, setCountryFunc) => {
    const countryCode = e.target.value;
    // update localStorage
    setCountryFunc(countryCode);
  };

  render() {
    const {
      classes,
      setCountry,
      countryCode,
      availableCountries,
      loading
    } = this.props;
    const countries = this.transformCountries(availableCountries);

    return (
      <>
        {!loading ? (
          <FormControl className={classes.root}>
            <Select
              value={countryCode}
              onChange={e => this.handleChange(e, setCountry)}
              displayEmpty
              disableUnderline
              variant="filled"
              inputProps={{
                className: classes.select
              }}
              MenuProps={{
                className: classes.menu,
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              }}
              IconComponent={ExpandMoreIcon}
            >
              {countries.map(item => (
                <MenuItem
                  key={item.code}
                  value={item.code}
                  selected={item.code === countryCode}
                  className={classes.option}
                >
                  <span>{countryToFlag(item.code)}</span>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Skeleton variant="rect" height={36} />
        )}
      </>
    );
  }
}

export default withStyles(styles)(CountrySelect);
