export const typography = () => ({
  typography: {
    // htmlFontSize: 10,
    fontFamily: 'Open Sans, Gilroy, Metropolis, Roboto, sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 500
      // fontSize: 35,
      // letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 51 / 32
    },
    h3: {
      fontWeight: 500
      // fontSize: 24,
      // letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20
    },
    h5: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26 / 16
    },
    h6: {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 21 / 13
    },
    overline: {
      fontWeight: 500
    },
    body1: {
      lineHeight: 1.2,
      letterSpacing: 'normal'
    },
    body2: {
      lineHeight: 1.2,
      letterSpacing: 'normal'
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1
    },
    caption: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 18 / 12
    }
  }
});
