import { isClientSide } from 'src/config';
import { ProductTypeEnum } from './enums';

export const sellGoldDepartment = '#3 Selling your RS Gold';
export const ordersDepartment = '#4 Order Delivery';
export const rewardsDepartment = '#5 Rewards Delivery';
export const accountsDepartment = '#6 OSRS Accounts';
export const stakerRentalDepartment = '#8 Maxed Staker Rental';
export const osrsItemsDepartment = '#9 Buy RS Items';

const validateZopim = () => {
  try {
    return $zopim;
  } catch (e) {
    // if (isClientSide()) console.log('Zopim error:', e);
  }
};

// const zopimWaitTime = 10;
// let zopimTryTimes = 0;
// let isZopimLoadIssueTracked = false;

export const waitForZopim = (user, fraudTag) => {
  const z = setInterval(() => {
    if (window.$zopim === undefined || window.$zopim.livechat === undefined) {
      // track zopim failed after 10seconds of inactivity
      // send request to backend only if we cannot recognize zopim longer than 10 seconds
      // be aware that we have 2 seconds delay for ZOPIM in google tag manager
      // if (zopimTryTimes >= zopimWaitTime && !isZopimLoadIssueTracked) {
      //   // TODO: show modal here
      //   isZopimLoadIssueTracked = true;
      // }
      // zopimTryTimes++; // increase try times by 1 once every 1000ms (based on interval time)
      return;
    }
    $zopim(() => {
      if (user && user.fullName) {
        $zopim.livechat.setName(user.fullName);
      }
      if (user && user.email) {
        $zopim.livechat.setEmail(user.email);
      }
      if (user && user.loyaltyDetails.currentLevel) {
        $zopim.livechat.addTags(user.loyaltyDetails.currentLevel.name);
      }
      if (fraudTag) {
        $zopim.livechat.addTags(fraudTag);
      }
    });
    clearInterval(z);
  }, 1000);
};

export const changeZopimPage = url => {
  if (!validateZopim()) return;
  const z = setInterval(() => {
    if (
      (window && window.$zopim === undefined) ||
      window.$zopim.livechat === undefined
    ) {
      // track zopim failed after 10seconds of inactivity
      // send request to backend only if we cannot recognize zopim longer than 10 seconds
      // be aware that we have 2 seconds delay for ZOPIM in google tag manager
      // if (zopimTryTimes >= zopimWaitTime && !isZopimLoadIssueTracked) {
      //   // TODO: show modal here
      //   isZopimLoadIssueTracked = true;
      // }
      // zopimTryTimes++; // increase try times by 1 once every 1000ms (based on interval time)
      return;
    }
    $zopim.livechat.sendVisitorPath({
      url
    });
    clearInterval(z);
  }, 1000);
};

export function showSellZopimMessage(goldQuantity, productName) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(sellGoldDepartment);
  $zopim.livechat.say(
    `Hi, I am looking to sell ${goldQuantity}M ${productName}`
  );
  $zopim.livechat.window.show();
}

export function showManualPurchaseDialog(
  price,
  paymentMethod,
  quantity,
  productName
) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(ordersDepartment);
  $zopim.livechat.say(
    `I would like to make a manual payment of ${price} for ${productName} with ${paymentMethod}`
  );
  $zopim.livechat.window.show();
}

export function showPurchaseDialog(
  productName,
  paymentMethod,
  orderNumber,
  rsn = null
) {
  if (!validateZopim()) return;
  let msg = `Hi, I've made a purchase of ${productName} via ${paymentMethod} Order ID ${orderNumber}`;
  if (rsn) {
    msg += ` RSN ${rsn}`;
  }
  $zopim.livechat.departments.setVisitorDepartment(ordersDepartment);
  $zopim.livechat.say(msg);
  $zopim.livechat.window.show();
}

export function showPurchaseItemDialog(
  productName,
  paymentMethod,
  orderNumber,
  rsn
) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(osrsItemsDepartment);
  $zopim.livechat.say(
    `Hi, I've made a purchase of ${productName} via ${paymentMethod} Order ID ${orderNumber} RSN ${rsn}`
  );
  $zopim.livechat.window.show();
}

export function showPurchaseAccountDialog(
  productName,
  paymentMethod,
  orderNumber
) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(accountsDepartment);
  $zopim.livechat.say(
    `Hi, I've made a purchase of ${productName} via ${paymentMethod} Order ID ${orderNumber}`
  );
  $zopim.livechat.window.show();
}

export function showRedeemDialog(productName, orderNumber, rsn) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(rewardsDepartment);
  $zopim.livechat.say(
    `Hi, I've redeemed ${productName} Order ID ${orderNumber} RSN ${rsn}`
  );
  $zopim.livechat.window.show();
}

export function showCaseRedeemDialog(productName, variantName, orderNumber) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(rewardsDepartment);
  $zopim.livechat.say(
    `Hi, I’ve opened ${productName} and won ${variantName} [Reward ID ${orderNumber}]`
  );
  $zopim.livechat.window.show();
}

export function showAccountRentalDialog(period, price) {
  if (!validateZopim()) return;
  $zopim.livechat.departments.setVisitorDepartment(stakerRentalDepartment);
  $zopim.livechat.say(
    `I'm looking to rent a Maxed Staker for ${period} hours for ${price} Millions`
  );
  $zopim.livechat.window.show();
}

export function showZopimDialog() {
  if (!validateZopim()) return;
  $zopim.livechat.window.show();
}
